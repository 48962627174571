import { Box } from "@mui/material"
import necon_logo from "./necon-logo.png"

export const Logo = () => (
  <Box display="flex" alignItems="center">
    <img
      src={necon_logo}
      style={{ height: 40, width: 30, marginRight: 5 }}
      alt="logo"
    />
    <span style={{ fontSize: 25, color: "rgba(46, 170, 112)" }}>LECON</span>
  </Box>
)
